
@media (max-width: 575px) {
    .main-section {
        background-image: url(../img/bg-main-mobile.jpg) !important;
    }
}

.catalog-item__statuses {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 10px;
    right: -10px;
}

.catalog-item__vip, .catalog-item__checked, .catalog-item__video, .catalog-item__indi, .catalog-item__top {
    display: block;
    width: 66px;
    height: 66px;
    margin-bottom: -6px;
}

.catalog-item__vip {
    background: url("../svg/icon-vip-full.svg") no-repeat center / contain;
}

.catalog-item__top {
    background: url('../svg/icon-top_2.svg?1') no-repeat center / contain;
}

.catalog-item__checked {
    background: url('../svg/icon-checked-full.svg') no-repeat center / contain;
}

.catalog-item__video {
    background: url('../svg/icon-video-full.svg') no-repeat center / contain;
}

.catalog-item__indi {
    background: url('../svg/icon-indi-full.svg') no-repeat center / contain;
}

@media (max-width: 575px) {
    .catalog-item__statuses {
        top: 2px;
        right: -2px;
    }

    .catalog-item__vip, .catalog-item__checked, .catalog-item__video, .catalog-item__indi, .catalog-item__top {
        width: 33px;
        height: 33px;
        margin-bottom: -3px;
    }
}
